@mixin flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin flexBetween {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin button {
	background-color: map-get($colors, primary);
	color: map-get($colors, white);
	padding: 0.7rem 1rem;
	display: inline-block;
	position: relative;
	border-radius: 3px;
	transition: $transition;

	&:hover {
		transform: scale(1.025);
	}
}
