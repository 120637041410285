$colors: (primary: #f76902, white: #ffffff, light: #f5f5f5, black: #000000);

$san-serif: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, Segoe UI, Arial, sans-serif;

$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$easing: cubic-bezier(0.5, -0.75, 0.7, 2);
$transition: all 300ms $easing;

$body: (font-family: $san-serif, color: map-get($colors, black), background-color: map-get($colors, light));

$base-url: "../../assets/";
