html,
body,
#root {
	width: 100%;
	height: 100%;
}

body {
	color: map-get($body, color);
	background-color: map-get($body, background-color);
	font-family: map-get($body, font-family);
	letter-spacing: 0.09rem;
}

a {
	color: map-get($colors, primary);
	font-weight: $medium;
	text-decoration: none;
}

section {
	padding: 0 1.25rem 3rem 1.25rem;

	> header {
		color: map-get($colors, primary);
		font-weight: bold;
		font-size: 2rem;
		margin-bottom: 1.25rem;
	}

	article {
		line-height: 1.5rem;
		text-align: justify;

		> p {
			margin-bottom: 1.25rem;
			font-weight: $light;
		}
	}
}

.app {
	background-color: map-get($colors, white);
	margin: 0 auto;
	max-width: 960px;
	width: 100%;
	min-height: 100%;
	box-shadow: 0 0 6px rgba(0, 0, 0, .2);
}
