.works {
	&__year {
		font-weight: bold;
		font-size: 1.5rem;
		margin-bottom: 1rem;
	}
}

.work {
	border-radius: 3px;
	border: 1px solid map-get($colors, primary);
	padding: 1rem;
	margin-bottom: 1rem;

	&__code {
		margin-right: .5rem;
		font-weight: $bold;
	}

	&__name {
		font-weight: $light;
	}

	&__description {
		margin-top: .5rem;
		font-weight: $light;
	}

	&__award {
		margin-top: .5rem;
		font-weight: $light;
		font-size: .9rem;
		font-style: italic;

		> svg {
			color: map-get($colors, primary);
		}
	}

	&__links {
		margin-top: 1rem;

		> a {
			margin-right: 1rem;
			display: inline-block;
		}
	}
}

@media screen and (max-width: 640px) {
	.work {
		&__links {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
		}
	}
}
