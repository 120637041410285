.about {
	padding-top: 5rem;

	&__content {
		display: flex;
	}

	&__me {
		margin-right: 1.25rem;
	}

	&__picture {
		background-image: image("me.jpg");
		flex: 0 0 250px;
		height: 300px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border: 4px solid map-get($colors, primary);
	}

	&__contact {
		@include button;
	}
}

@media screen and (max-width: 640px) {
	.about {
		&__content {
			flex-direction: column;
		}

		&__me {
			order: 2;
			margin-right: 0;
		}

		&__picture {
			order: 1;
			margin-bottom: 1rem;
		}
	}
}
