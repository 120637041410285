.header {
	@include flexCenter;

	width: 100%;
	max-width: 960px;
	height: 70px;
	padding: 0 1.25rem;
	position: fixed;
	top: 0;
	z-index: 1;
	transition: $transition;
	background-color: map-get($colors, white);

	&--scroll {
		border-bottom: 3px solid map-get($colors, primary);
		box-shadow: 0px 4px 8px rgba(map-get($colors, black), 0.3);
	}
}

.nav {
	@include flexBetween;

	width: 100%;

	&__brand {
		@include flexBetween;

		text-transform: uppercase;
		font-size: 1.25rem;
		font-weight: $light;
	}

	&__menu {
		display: flex;
		align-items: center;
	}

	&__list {
		@include flexBetween;
	}

	&__link {
		margin: 0 1rem;
		position: relative;

		.active {
			&:before {
				width: 100%;
			}
		}

		a {
			cursor: pointer;

			&:before {
				background-color: map-get($colors, primary);
				content: "";
				height: 2px;
				position: absolute;
				bottom: -5px;
				left: 50%;
				transform: translateX(-50%);
				transition: $transition;
				width: 0;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}
	}

	&__cv {
		margin-left: 1rem;

		a {
			@include button;
		}
	}

	&__expand {
		font-size: 1.5em;
		color: map-get($colors, primary);
		visibility: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}

	.cover {
		display: none;
	}
}

@media screen and (max-width: 640px) {
	.nav {
		&__expand {
			visibility: visible;
			z-index: 2;
		}

		&__menu {
			position: absolute;
			top: 0;
			right: 0;
			height: 100vh;
			width: 50vw;
			background-color: white;
			display: flex;
			flex-direction: column;
			box-shadow: none;
			transform: translateX(50vw);
			transition: 300ms;
			z-index: 1;
		}

		&__list {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-end;
			background-color: map-get($colors, dark);
			right: 0;
			width: 50vw;
			padding-top: 5rem;

			li {
				text-align: right;
				margin-bottom: 1.75rem;
				display: inline-block;
			}
		}

		&__cv {
			margin: 0 1rem;
			text-align: right;
		}

		&__menu--open {
			visibility: visible;
			transform: translateX(0vw);
			box-shadow: -10px 0px 5px 0px rgba(0, 0, 0, 0.1);
		}

		.cover {
			background-color: black;
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			opacity: 0;
			transition: 300ms;

			&--show {
				opacity: 0.3;
				cursor: pointer;
				display: block;
			}
		}
	}
}
